import { Fragment, useState } from "react";
import { Stream } from "@cloudflare/stream-react";
import { Button, Card, CardBody, Modal, ModalBody, Row } from "reactstrap";
import { getThumbnailUrl } from "../../helper/getThumbnailUrl";
import Loading from "../../components/Loading";
import "./MovementVideoCard.scss";
import Video from "../../interfaces/models/Video";

type MovementVideoCardProps = {
  className?: string;
  video: Video;
  onThumbnailLoad?: () => void;
};

export default function MovementVideoCard(props: MovementVideoCardProps) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <>
      <Card
        className={`${props.className} h-100 shadow`}
        id="nav-card"
        style={{ cursor: "pointer" }}
      >
        <div className={"card-img-top image-card"}>
          <Row className="justify-content-center">
            <img
              onLoad={props.onThumbnailLoad}
              onClick={toggle}
              className="movement-card-thumbnail"
              src={getThumbnailUrl(props.video.video_url)}
              alt={props.video.description}
            />
          </Row>
        </div>
        <CardBody>
          <h5 className="text-uppercase text-muted mb-0 card-title">
            {props.video.description.length > 30
              ? props.video.description.slice(0, 25) + "..."
              : props.video.description}
          </h5>
          <Row className="justify-content-center">
            {props.video.video_url && (
              <Button className="btn-sm" onClick={toggle}>
                <span className="btn-inner--icon">
                  <i className="fa fa-play" aria-hidden="true" />
                </span>
                <span className="btn-inner--text">Open video</span>
              </Button>
            )}
          </Row>
        </CardBody>
      </Card>
      <div>
        <Modal size="xl" isOpen={modal} toggle={toggle}>
          <ModalBody>
            <MovementVideoPlayer url={props.video.video_url} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
interface MovementVideoPlayerProps {
  url: string;
}

function MovementVideoPlayer(props: MovementVideoPlayerProps) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const videoId = (id) => id.match(/video.\b\w([.^\S]*)\w*\b/gm)[0];

  return (
    <Fragment>
      {!loaded && <Loading />}
      <Stream
        className={!loaded && "invisible"}
        onLoadedData={() => setLoaded(true)}
        controls
        autoplay={true}
        src={videoId(props.url)}
      />
    </Fragment>
  );
}
