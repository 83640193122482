import React, { useCallback, useEffect, useMemo } from "react";
import { scaleTime, scaleLinear } from "@visx/scale";
import { extent, max } from "d3-array";
import { timeWeek } from "d3-time";
import moment from "moment";
import { AreaSeparationChart } from "./AreaSeparationChart";

const PerformanceGraph = ({
  axisOrientationX = "top",
  axisOrientationY = "left",
  timeFormatter = (date) => moment(date).format("DD-MMM-yyyy"),
  data,
  displayMode,
  inspectMode,
  setInspectMode,
  margin = { top: 30, right: 25, bottom: 30, left: 25 },
  periods,
  strokeY = "var(--black)",
  strokeWidthX = 0,
  strokeWidthY = 0,
  setPeriods,
  tickStrokeY = "rgba(0,0,0,0.8)",
  width,
  height,
}) => {
  // Graph width and height
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const task_assignments = useMemo(
    () =>
      data
        ? data
            .filter(
              (ta) =>
                ta.user_acknowledged &&
                ta.task_complete &&
                ta.user_acknowledged_at
            )
            .reduce((aggs, ta) => {
              const index = aggs?.findIndex(
                (c) => c.date === timeFormatter(ta.user_acknowledged_at)
              );
              if (index !== -1) {
                const userIndex = aggs[index].user_ids?.indexOf(ta.user_id);
                if (userIndex === -1) {
                  // counts how many users in the same day
                  aggs[index].users++;
                  aggs[index].user_ids.push(ta.user_id);
                }
                // counts how many tasks all of them had
                aggs[index].temp_count++;
              } else {
                aggs.push({
                  date: timeFormatter(ta.user_acknowledged_at),
                  temp_count: 1,
                  user_ids: [ta.user_id],
                  users: 1,
                });
              }

              return aggs;
            }, [])
            .filter((t) => t.date !== "Invalid date")
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )
            .map((t) => ({ ...t, count: t.temp_count / t.users })) // get average count by divide activity number with how many user in that day
        : [],
    [data, timeFormatter]
  );

  const groupBy = useCallback((list, getKey) => {
    const map = new Map();
    list.forEach((item) => {
      const key = getKey(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  }, []);

  const graph_data = useMemo(() => {
    // group activity by week or month
    const getKey =
      displayMode === "Weekly"
        ? (t) => moment(t?.date).week()
        : (t) => moment(t.date).month();

    const map = groupBy(task_assignments, getKey);

    return Array.from(map.values()).map((m) =>
      m.reduce(
        (agg, ta) => ({
          ...agg,
          date: new Date(ta.date),
          count:
            ta.count + Math.round(((agg?.count || 0) / m.length) * 100) / 100,
          users: ta.users + (agg?.users || 0),
        }),
        {}
      )
    );
  }, [displayMode, groupBy, task_assignments]);

  const tick_format = (date) => {
    const formatWeek = moment(date).format("DD-MMM-YY");
    const formatMonth = moment(date).format("MMM-YY");

    return displayMode === "Weekly" ? formatWeek : formatMonth;
  };

  // set period to the display
  useEffect(() => {
    if (graph_data && graph_data.length && periods.length < 2)
      setPeriods(extent(graph_data, getX));
  }, [graph_data, setPeriods, periods]);

  // accessors
  const getX = (d) => new Date(d.date);
  const getY = (d) => d.count;

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, innerWidth],
        domain: extent(graph_data, getX),
      }),
    [graph_data, innerWidth, margin]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight - margin.bottom, margin.top],
        domain: [0, parseInt(max(graph_data, getY)) + 1],
        nice: true,
      }),
    [graph_data, margin, innerHeight]
  );

  const tick_value = useMemo(() => {
    switch (displayMode) {
      case "Weekly":
        return xScale.ticks(timeWeek).filter((_, i) => i % 2 === 0); // reduce amount of ticks
      case "Monthly":
        return graph_data.map((g) => getX(g));
      default:
        return;
    }
  }, [displayMode, graph_data, xScale]);

  const xAxisProperties = {
    orientation: axisOrientationX,
    scale: xScale,
    strokeWidth: strokeWidthX,
    tickFormat: tick_format,
    tickLabelProps: () => ({
      textAnchor: "middle",
      style: {
        fill: "var(--gray)",
        fontSize: "0.7em",
        transform: "translateY(0px)",
      },
    }),
    tickValues: tick_value,
    top: innerHeight + 20,
  };

  const yAxisProperties = {
    orientation: axisOrientationY,
    scale: yScale,
    stroke: strokeY,
    strokeWidth: strokeWidthY,
    tickLabelProps: () => ({
      alignmentBaseline: "middle",
      style: {
        fill: "var(--gray)",
        fontSize: "0.7em",
        transform: "translateX(-5px)",
      },
    }),
    tickValues: yScale.ticks().filter((x) => x % 2 === 0),
    tickLength: 0,
    tickStroke: tickStrokeY,
  };

  return (
    <div className="d-flex justify-content-center">
      <AreaSeparationChart
        {...{
          displayMode,
          data: graph_data,
          getY,
          getX,
          height,
          innerHeight,
          innerWidth,
          inspectMode,
          setInspectMode,
          margin,
          timeFormatter,
          tick_value,
          width,
          xAxisProperties,
          yAxisProperties,
          xScale,
          yScale,
        }}
      />
    </div>
  );
};

export { PerformanceGraph };
