import { useState, useEffect } from "react";

const MOBILE_SCREEN_WIDTH = 600;
const MEDIUM_SCREEN_WIDTH = 1600;

type ScreenSizeCategory = "mobile" | "medium" | "large";

const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [screenSizeCategory, setScreenSizeCategory] =
    useState<ScreenSizeCategory>(getSizeCategory(window.innerWidth));

  function getSizeCategory(size: number): ScreenSizeCategory {
    if (size < MOBILE_SCREEN_WIDTH) {
      return "mobile";
    } else if (size >= MOBILE_SCREEN_WIDTH && size < MEDIUM_SCREEN_WIDTH) {
      return "medium";
    } else {
      return "large";
    }
  }

  const handleResize = () => {
    const size = window.innerWidth;
    setWindowSize(size);
  };

  useEffect(() => {
    const currentCategory = getSizeCategory(windowSize);
    if (currentCategory !== screenSizeCategory) {
      setScreenSizeCategory(currentCategory);
    }
  }, [windowSize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { windowSize, screenSizeCategory };
};

export { useWindowResize };
