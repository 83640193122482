import { useEffect, useState } from "react";
import moment from "moment";
import { ActivityRings } from "@jonasdoesthings/react-activity-rings";
import { Card, CardBody } from "reactstrap";
import ActivityRingLegend, {
  DeskCoachActivityRing,
} from "./ActivityRingLegend";
import { TaskAssignment } from "../../../interfaces/models/TaskAssignment";
import ConfettiExplosion from "react-confetti-explosion";
import trophyicon from "../../../assets/img/icons/common/trophy.svg";
import styles from "./TasksCompletedActivityRing.module.scss";

interface TasksCompletedActivityRingProps {
  assignments: TaskAssignment[];
}

export default function TasksCompletedActivityRing(
  props: TasksCompletedActivityRingProps
) {
  const [isExploding, setIsExploding] = useState<boolean>(false);
  const [showTrophy, setShowTrophy] = useState<boolean>(false);

  // Check on first page load whether confetti has already been shown today
  useEffect(() => {
    const storedDate = localStorage.getItem("storedDate");

    if (storedDate !== null) {
      const todayDate = moment().format("YYYY-MM-DD");
      if (storedDate !== todayDate) {
        // Cleanup old storedDates
        localStorage.removeItem("storedDate");
      } else {
        setShowTrophy(true);
      }
    }
  }, []);

  const todayDate = moment().format("YYYY-MM-DD");
  const nudgesCompleted = props.assignments.filter(
    (task) =>
      task.task_complete &&
      moment(task.assigned_at).format("YYYY-MM-DD") === todayDate
  );

  // Show confetti after 1.5 seconds - if user has completed 3 nudges today
  useEffect(() => {
    if (!showTrophy && nudgesCompleted.length >= 3) {
      localStorage.setItem("storedDate", todayDate);
      const timer = setTimeout(() => {
        setIsExploding(true);
        setShowTrophy(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [props.assignments]);

  const activityData: DeskCoachActivityRing[] = [
    {
      label: "Psychosocial",
      filledPercentage: nudgesCompleted.length > 0 ? 1 : 0,
      color: "var(--pyschosocial)",
    },
    {
      label: "Physical",
      filledPercentage: nudgesCompleted.length > 1 ? 1 : 0,
      color: "var(--physical)",
    },
    {
      label: "Nutrition",
      filledPercentage: nudgesCompleted.length > 2 ? 1 : 0,
      color: "var(--royal-blue)",
    },
  ];

  return (
    <Card className="h-100">
      <CardBody>
        <div
          className={`${styles.transitionItem} ${
            !showTrophy && styles.transitionItemActive
          }`}
        >
          <div className="d-flex flex-row justify-content-between flex-wrap mb-4">
            <h3>Your Progress</h3>
            <ActivityRingLegend activities={activityData} />
          </div>

          <div style={{ position: "relative" }}>
            <ActivityRings
              rings={activityData}
              options={{
                initialRadius: 38,
                animationDurationMillis: 1500,
                paddingBetweenRings: 0,
              }}
            />
            {isExploding && (
              <ConfettiExplosion
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                }}
                width={2000}
                duration={4400}
                particleCount={220}
                onComplete={() => {
                  setIsExploding(false);
                }}
              />
            )}
          </div>
        </div>

        <div
          className={`${styles.trophyContainer} ${styles.transitionItem} ${
            showTrophy && styles.transitionItemActive
          }`}
        >
          <TrophyCard />
        </div>
      </CardBody>
    </Card>
  );
}

const TrophyCard = () => {
  return (
    <>
      <h3 className={styles.tropyTitle}>Congratulations, YOU did it!</h3>
      <div className={`${styles.centerContent}`}>
        <img src={trophyicon} style={{ height: "65%" }} alt="trophy-icon" />
      </div>
    </>
  );
};
