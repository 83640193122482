import { Card, CardBody } from "reactstrap";
import target from "../../assets/img/icons/common/target.svg";

// interface SpotlightProps {
//   image: string;
//   title: string;
//   description?: string;
//   link?: string;
// }

export default function SpotlightCard() {
  // TODO: Data displayed in this component is temporarily hardcoded - in future DC wish to have the ability to adjust this

  return (
    <Card className="h-100">
      <CardBody>
        <h3>Spotlight</h3>
        <div className="d-flex flex-column align-items-center">
          <img
            style={{ width: "80%", marginBottom: "1rem" }}
            src={target}
            alt="Spotlight art for target"
          />
          <h2 style={{ fontWeight: "bold" }}>Healthy Habits</h2>
          <p>4 simple tips for forming healthy habits</p>
          <a
            style={{ fontSize: "0.9rem" }}
            href="https://www.deskcoach.com.au/post/4-simple-tips-for-healthy-habit-building"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here for more info
          </a>
        </div>
      </CardBody>
    </Card>
  );
}
