import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import Loading from "../Loading";
import editTaskValidator from "../../validators/EditTaskValidator";
import TextFormInput from "../Shared/Forms/TextFormInput";
import { Task } from "../../interfaces/models/Task";
import CloudFlareImageFormInput from "../Shared/Forms/ImageFormInput";
import VideoFormInput from "../Shared/Forms/VideoFormInput";
import CustomToggleButtonGroup from "../Shared/CustomToggleButtonGroup";
import FormError from "../Shared/Forms/FormError";
import useImageUpload from "../../hooks/useImageUpload";
import useVideoUpload from "../../hooks/useVideoUpload";
import ImageSelectedCard from "../Shared/Forms/ImageSelectedCard";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getVideoUploadURLQuery } from "../../graphql/queries/getVideoUploadURL";
import { updateTaskById } from "../../graphql/updateTaskById";
import { useHistory } from "react-router-dom";
import {
  NudgeClickUrlType,
  getNudgeClickUrlType,
} from "../../interfaces/models/NudgeClickUrlType";
import { CloudflareSignedFile } from "../../interfaces/CloudflareSignedFile";
import { removeElementAtIndex } from "../../helper/ArrayHelpers/removeElementAtIndex";
import styles from "../../assets/css/EditTaskModal.module.css";
import { getVideoUrlFromCloudflareId } from "../../helper/getVideoUrlFromCloudflareId";

const ACTION_URL_LINK: string = "action_url_link";
const ACTION_URL_VIDEO: string = "action_url_video";

interface EditTaskModalProps {
  initialTask: Task;
  onClose: () => void;
}

const imageUploadFailedMessage: string =
  "Something went wrong whilst uploading image(s). Please try again or if issue persists, please contact a System Admin";
const videoUploadFailedMessage: string =
  "Something went wrong whilst uploading video, please check that the video duration is less than 10 minutes and that a valid connection is present and try again. If the issue persists, please contact a System Administrator";

export default function EditTaskModal({
  initialTask,
  onClose,
}: EditTaskModalProps) {
  const history = useHistory();
  const formikRef = useRef<FormikProps<Task>>(null);
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const [actionUrlType, setActionUrlMode] = useState<NudgeClickUrlType>(
    getNudgeClickUrlType(initialTask?.action_url)
  );
  const { uploadImage } = useImageUpload();
  const { uploadVideo, uploadingVideo, uploadingVideoProgress } =
    useVideoUpload();
  const [imagesToUpload, setImagesToUpload] = useState<CloudflareSignedFile[]>(
    []
  );
  const [previewImgUrls, setPreviewImgUrls] = useState<string[]>(
    initialTask?.images
  );
  const [selectedVideoFile, setSelectedVideoFile] = useState<File>(null);
  const [videoToUpload, setVideoToUpload] =
    useState<CloudflareSignedFile>(null);
  const [
    getSignedVideoUrl,
    { loading: gettingSignedVideoUrl, error: signedVideoUrlError },
  ] = useLazyQuery(getVideoUploadURLQuery, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data.getVideoUploadURL) {
        setVideoToUpload({
          cloudflareId: data.getVideoUploadURL.cloudflareId,
          uploadUrl: data.getVideoUploadURL.uploadURL,
          file: selectedVideoFile,
        });
      } else {
        toast.error(videoUploadFailedMessage);
        setSelectedVideoFile(null);
      }
    },
    onError() {
      toast.error(videoUploadFailedMessage);
      setSelectedVideoFile(null);
    },
  });
  const [updateTask, { loading: updatingTask }] = useMutation(updateTaskById, {
    onCompleted: () => {
      toast.success("Task was successfully updated");
      onClose && onClose();
      history.go(0); //Refresh Page, to update with new sequence
    },
    onError() {
      toast.error(
        "Something went wrong whilst updating the task. Please try again or if issue persists, please contact a System Admin"
      );
      setSubmittingForm(false);
    },
  });

  const handleAddImage = (image: CloudflareSignedFile, index: number) => {
    // Adds image to preview and signed arrays at index
    const newPreviewImages = [...previewImgUrls];
    newPreviewImages[index] = URL.createObjectURL(image.file);
    setPreviewImgUrls(newPreviewImages);

    const newSignedImages = [...imagesToUpload];
    newSignedImages[index] = image;
    setImagesToUpload(newSignedImages);
  };

  const handleRemoveImage = (index: number, formik: FormikProps<Task>) => {
    setPreviewImgUrls(removeElementAtIndex([...previewImgUrls], index));
    setImagesToUpload(removeElementAtIndex([...imagesToUpload], index));
    // Clear instructions for removed image
    const updatedInstructions = removeElementAtIndex(
      [...formik.values.instructions],
      index
    );
    formik.setFieldValue("instructions", updatedInstructions);
  };

  // Checks for any altered images in the form from the inital values - and attempts to upload them to Cloudflare
  const uploadAnyNewImages = async (): Promise<boolean> => {
    let uploadRequests = [];
    for (let i = 0; i < imagesToUpload.length; i++) {
      const signedImage = imagesToUpload[i];
      // Handle edge case where inserting image into index 1 when array is initally empty - will insert `null` by default at 0th index
      if (signedImage === undefined || signedImage === null) {
        continue;
      }
      uploadRequests.push(uploadImage(signedImage.uploadUrl, signedImage.file));
    }

    const uploadsAreSuccessful = await Promise.all(uploadRequests);
    const anyUploadFailed = uploadsAreSuccessful.some((success) => !success);
    if (anyUploadFailed) {
      return false;
    }

    // Clear signed images from being uploaded again in the edge case where task update fails
    setImagesToUpload([]);
    return true;
  };

  const handleVideoSelect = (video: File) => {
    setSelectedVideoFile(video);
    getSignedVideoUrl({
      variables: {
        fileName: video.name,
        fileSize: video.size,
      },
    });
  };

  const submitForm = async (formik: FormikProps<Task>) => {
    setSubmittingForm(true);

    // Upload any new image(s) to cloudflare - a boolean is returned indicating whether any errors occurred i.e No errors occurred => returns false
    const successful = await uploadAnyNewImages();
    if (!successful) {
      setSubmittingForm(false);
      toast.error(imageUploadFailedMessage);
      return;
    }

    if (actionUrlIsVideo && videoToUpload !== null) {
      await uploadVideo(videoToUpload.uploadUrl, videoToUpload.file, {
        onError: () => {
          setSubmittingForm(false);
          toast.error(videoUploadFailedMessage);
          return;
        },
        onSuccess: () => {
          formik.submitForm();
        },
      });
    } else {
      formik.submitForm();
    }
  };

  const actionUrlIsVideo = actionUrlType === NudgeClickUrlType.Video;
  const disableFormControlBtns =
    uploadingVideo || gettingSignedVideoUrl || updatingTask;

  const getInitialValues = () => {
    const initialValues = {
      name: initialTask.name,
      description: initialTask.description,
      images: initialTask.images,
      instructions: initialTask.instructions,
    };
    const nudgeType = getNudgeClickUrlType(initialTask?.action_url);
    if (nudgeType == NudgeClickUrlType.Link) {
      initialValues[ACTION_URL_VIDEO] = initialTask.action_url;
    } else {
      initialValues[ACTION_URL_LINK] = initialTask.action_url;
    }
    return initialValues;
  };

  return (
    <Modal
      isOpen={true}
      className="modal-lg"
      toggle={() => onClose && onClose()}
    >
      <ModalHeader
        style={{ paddingBottom: "10px" }}
        toggle={() => {
          onClose && onClose();
        }}
      >
        <p className="text-lg">Edit Task</p>
      </ModalHeader>
      <ModalBody style={{ paddingTop: "0px" }}>
        <Formik
          innerRef={formikRef}
          validationSchema={editTaskValidator}
          initialValues={getInitialValues()}
          onSubmit={async (values) => {
            // Assign the action_url to be whichever toggle button is selected on submission
            const actionUrl = actionUrlIsVideo
              ? values?.[ACTION_URL_VIDEO]
              : values?.[ACTION_URL_LINK];
            values.action_url =
              actionUrl !== undefined
                ? actionUrl
                : initialTask.action_url ?? null;

            // Ensure if action_url is empty string - it is first converted to `null`
            if (actionUrlType === NudgeClickUrlType.Link) {
              values.action_url =
                values.action_url === "" ? null : values.action_url;
            } else {
              if (videoToUpload) {
                //If a new video was uploaded, we should update the action url to use the presigned url id.
                const newVideoUrl = getVideoUrlFromCloudflareId(
                  videoToUpload.cloudflareId
                );
                values.action_url = newVideoUrl;
              }
            }

            updateTask({
              variables: {
                _id: initialTask._id,
                record: {
                  name: values.name,
                  description: values.description,
                  images: values.images,
                  instructions: values.instructions,
                  action_url: values.action_url,
                },
              },
            });
          }}
        >
          {(formik: FormikProps<Task>) => (
            <Form className="d-flex flex-column">
              <TextFormInput
                name={"name"}
                placeholder="Enter task name"
                label="Name"
                required={true}
                className={`${styles.mediaFormInput}`}
              />
              <TextFormInput
                name={"description"}
                placeholder="Enter task description"
                label="Description"
                required={true}
                className={`${styles.mediaFormInput}`}
              />
              <label className="whitespace-nowrap pb-2">Images*</label>
              <FieldArray
                name="images"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.images.map((image, index) => (
                      <ImageSelectedCard
                        key={index}
                        name={`image.${index}`}
                        index={index}
                        fileName={image}
                        imgUrl={previewImgUrls[index]}
                        deselectMedia={() => {
                          handleRemoveImage(index, formik);
                          arrayHelpers.remove(index);
                        }}
                      />
                    ))}
                    {/* Restrict to 2 Images only for a Task */}
                    {formik.values.images.length < 2 && (
                      <CloudFlareImageFormInput
                        index={formik.values.images.length}
                        placeholder="Select an image to upload..."
                        className={`${styles.mediaFormInput}`}
                        onImageSelect={handleAddImage}
                      />
                    )}
                  </>
                )}
              />
              <FormError>{formik.getFieldMeta("images").error}</FormError>
              <label>Nudge Click URL</label>
              <CustomToggleButtonGroup
                disabled={submittingForm}
                buttons={[
                  {
                    label: "Link",
                    onSelected: () => {
                      setActionUrlMode(NudgeClickUrlType.Link);
                      setVideoToUpload(null);
                      formik.setFieldValue(ACTION_URL_VIDEO, undefined);
                    },
                  },
                  {
                    label: "Video",
                    onSelected: () => {
                      setActionUrlMode(NudgeClickUrlType.Video);
                      if (
                        actionUrlType !=
                        getNudgeClickUrlType(initialTask.action_url)
                      ) {
                        formik.setFieldValue(
                          ACTION_URL_VIDEO,
                          initialTask.action_url
                        );
                      }
                    },
                  },
                ]}
                initiallySelectedIndex={!actionUrlIsVideo ? 0 : 1}
              />
              {!actionUrlIsVideo ? (
                <TextFormInput
                  name={ACTION_URL_LINK}
                  placeholder="Enter a URL (leaving this blank will default the URL to this task)"
                  className={`${styles.mediaFormInput}`}
                  defaultValue={initialTask.action_url}
                />
              ) : (
                <VideoFormInput
                  name={ACTION_URL_VIDEO}
                  placeholder="Select a video to upload..."
                  className={`${styles.mediaFormInput}`}
                  maxVideoDuration={10 * 60} //Ten minutes
                  defaultValue={
                    getNudgeClickUrlType(initialTask.action_url) ==
                    NudgeClickUrlType.Video
                      ? initialTask.action_url
                      : undefined
                  }
                  onSelect={handleVideoSelect}
                  signedUrlError={signedVideoUrlError}
                />
              )}
              {/* Video upload progress bar */}
              {uploadingVideoProgress && (
                <>
                  <Progress value={uploadingVideoProgress} />
                  <p>Uploading video...</p>
                </>
              )}
              <div className="d-flex flex-row align-self-end">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => onClose && onClose()}
                  disabled={submittingForm || disableFormControlBtns}
                >
                  Cancel
                </button>
                <button
                  id="submit-form-button"
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    submitForm(formik);
                  }}
                  disabled={
                    submittingForm || !formik.isValid || disableFormControlBtns
                  }
                >
                  {submittingForm ? <Loading size="sm" /> : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
