import { useState, useEffect } from "react";
import { azureConfig } from "../config/auth/azure.config";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Loading from "./Loading";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import _ from "lodash";
const SecureApp = (props) => {
  const { instance, inProgress, accounts } = useMsal();
  const [isInitialised, setIsInitialised] = useState(false);
  const request = {
    ...azureConfig,
    account: accounts[0],
  };
  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    await asyncTokenLookup();
    setIsInitialised(true);
  };

  const asyncTokenLookup = async () => {
    const accounts = await instance.getAllAccounts();
    const account = accounts[0];
    if (account && inProgress === InteractionStatus.None) {
      try {
        const result = await instance.acquireTokenSilent({
          ...request,
          forceRefresh: true,
          account,
        });
        return result.idToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(request);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      return instance.acquireTokenRedirect(request);
    }
  };

  if (!isInitialised) {
    return <Loading />;
  } else {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_DATA_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await asyncTokenLookup();
      return {
        headers: {
          ...headers,
          authorization: token,
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }
};

export default SecureApp;
