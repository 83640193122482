import { getImageUrl } from "../../helper/getImageUrl";
import "../../_css/components/notification-categories-cards.css";
import { Card, CardBody } from "reactstrap";

interface CategoryCardProps {
  image: string; //Actually the image url..
  description: string; //Actually the title..
}

export function CategoryCard(props: CategoryCardProps) {
  return (
    <>
      <Card
        className="position-relative notification-cats-card hovering-effect mb-4 mb-xl-0 h-100 overflow-hidden shadow-lg overlay"
        style={{
          //@ts-ignore
          "--backgroundImage": `url(${getImageUrl(props.image)})`,
        }}
      >
        <CardBody className="card-background d-flex justify-content-center align-items-center">
          <h5 className="display-4 text-center text-uppercase text-muted text-white">
            {props.description}
          </h5>
        </CardBody>
      </Card>
    </>
  );
}
