import moment from "moment";
import { NavLink } from "react-router-dom";

export interface TimelineTitleProps {
  description: string;
  path?: string;
  date?: Date;
}

export function TimelineTitle(props: TimelineTitleProps) {
  const Wrapper = props.path ? NavLink : "span";

  return (
    <div>
      {props.date && (
        <small className="text-muted font-weight-bold">
          {props.date && <span>{moment(props.date).format("HH:mm")} - </span>}
        </small>
      )}
      <Wrapper
        aria-disabled="true"
        className="mt-1 h5 ps-1 pe-auto"
        to={props.path}
      >
        {props.description}
      </Wrapper>
    </div>
  );
}
