import { ActivityRing } from "@jonasdoesthings/react-activity-rings/dist/types";

export interface DeskCoachActivityRing extends ActivityRing {
  label: string;
}

interface LegendProps {
  activities: DeskCoachActivityRing[];
}

export default function ActivityRingLegend(props: LegendProps) {
  return (
    <div>
      <div className="d-flex flex-column">
        {props.activities.map((activity, i) => {
          return (
            <div key={i} className="d-flex align-items-center">
              <i style={{ color: activity.color }} className="fas fa-circle mr-3" />
              <p style={{ margin: 0, fontSize: "small", fontWeight: "bold" }}>
                {activity.label}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
