import { Card, CardBody } from "reactstrap";
import socialEnterprise from "../../assets/img/icons/common/epidemiology.svg";

export default function SocialEnterpriseCard() {
  return (
    <Card className="h-100">
      <CardBody>
        <em>Coming soon!</em>
        <h3>Make a Difference</h3>
        <p>Your desk.coach efforts contribute to others.</p>
        <div className="d-flex justify-content-center items-center">
          <img
            style={{ width: '80%' }}
            src={socialEnterprise}
            alt={"social-enterprise-img"}
          />
        </div>
      </CardBody>
    </Card>
  );
}
