import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import FormError from "./FormError";
import VideoSelectedCard from "./VideoSelectedCard";
import toast from "react-hot-toast";
import styles from "../../../assets/css/EditTaskModal.module.css";
import { ApolloError } from "@apollo/client";

type Props = {
  name: string;
  placeholder: string;
  label?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  maxVideoDuration: number; //Maximum duration in seconds
  onSelect?: (video: File) => void;
  signedUrlError?: ApolloError;
};

type SelectedFile = {
  name: string;
  url: string;
};

const videoTypesAllowed =
  "video/mp4,video/mkv,video/quicktime,video/avi,video/x-flv,video/mp2t,video/mp2p,video/mxf,video/lxf,video/gxf,video/3gpp,video/webm,video/mpg,video/quicktime";

export default function VideoFormInput(props: Props) {
  const formikContext = useFormikContext();
  const [selectedFile, setSelectedFile] = useState<SelectedFile>(
    props.defaultValue
      ? { name: props.defaultValue, url: props.defaultValue }
      : null
  );

  // Deselects the video if an error occurs during signed url acquisition
  useEffect(() => {
    if (props.signedUrlError) {
      selectFile(null);
    }
  }, [props.signedUrlError]);

  const selectFile = (file?: SelectedFile) => {
    setSelectedFile(file ? { name: file.name, url: file.url } : null);
    formikContext.setFieldValue(props.name, file?.url ?? null);
  };

  const handleFileChange = async (e) => {
    const file: File = e.target.files[0];
    if (file) {
      // Ensure file selected is correct format
      const file_ext = file.name.match(/\.([^.]+)$/)[1];
      if (!videoTypesAllowed.includes(file_ext)) {
        toast.error("This file format is not supported");
        return;
      }

      const url = URL.createObjectURL(file);
      const isValidDuration = await validateVideoDuration(
        url,
        props.maxVideoDuration
      );
      if (isValidDuration) {
        selectFile({ name: file.name, url: url });
        props.onSelect(file);
      } else {
        toast.error(
          `The selected video exceeds the maximum duration of ${props.maxVideoDuration} seconds.`
        );
      }
    } else {
      selectFile(null);
    }
  };

  const validateVideoDuration = (
    videoUrl: string,
    maxDurationSeconds: number
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        resolve(duration <= maxDurationSeconds);
      };

      video.onerror = function (event) {
        reject(new Error(`Unexpected error loading video metadata - ${event}`));
      };

      video.src = videoUrl;
    });
  };

  return (
    <>
      {props.label && (
        <label className="whitespace-nowrap	pb-2">
          {props.label} {props.required && "*"}
        </label>
      )}

      <div className={`flex flex-col ${props.className}`}>
        {selectedFile ? (
          <VideoSelectedCard
            fileName={selectedFile.name}
            fileUrl={selectedFile.url}
            deselectMedia={() => selectFile(null)}
          />
        ) : (
          <>
            {/* File Selector */}
            <label
              htmlFor={props.name}
              className={`form-control ${styles.fileSelector}`}
            >
              <span>{props.placeholder}</span>
              <i className="fas fa-upload fa-2xl"></i>
            </label>
            <Input
              id={props.name}
              type="file"
              accept={videoTypesAllowed}
              disabled={props.disabled}
              status={
                formikContext.getFieldMeta(props.name).error &&
                formikContext.getFieldMeta(props.name).touched
                  ? "error"
                  : ""
              }
              onChange={handleFileChange}
            />
            <FormError>
              {formikContext.getFieldMeta(props.name).error}
            </FormError>
          </>
        )}
      </div>
    </>
  );
}
